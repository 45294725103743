import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from '@src/store/store';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

interface ITxId {
  txId: string;
  name: string;
  time: number;
}

export interface ITransaction {
  pendingMintTransaction: ITxId;
  pendingDelegateTransaction: ITxId;
  pendingDRepRegistrationTransaction: ITxId;
  pendingDRepRetireTransaction: ITxId;
  pendingVotingTransaction: ITxId;
}

const initialTxState = { txId: '', name: '', time: 0 };

const initialState: ITransaction = {
  pendingMintTransaction: initialTxState,
  pendingDelegateTransaction: initialTxState,
  pendingDRepRegistrationTransaction: initialTxState,
  pendingDRepRetireTransaction: initialTxState,
  pendingVotingTransaction: initialTxState,
};

export const transactionSlice = createSlice({
  name: 'pendingTransactions',
  initialState,
  reducers: {
    setPendingTransactions: (state, action: PayloadAction<ITransaction>) => {
      return { ...action.payload };
    },
    resetMintpendingTransaction: (state) => {
      state.pendingMintTransaction = initialTxState;
    },
    resetDelegatePendingTransaction: (state) => {
      state.pendingDelegateTransaction = initialTxState;
    },
    resetDRepRegistrationPendingTransaction: (state) => {
      state.pendingDRepRegistrationTransaction = initialTxState;
    },
    resetDRepRetirePendingTransaction: (state) => {
      state.pendingDRepRetireTransaction = initialTxState;
    },
    resetVotingPendingTransaction: (state) => {
      state.pendingVotingTransaction = initialTxState;
    },
  },
});

const transactionPersistConfig = {
  key: 'pendingTransactions',
  storage,
  whitelist: [
    'pendingMintTransaction',
    'pendingDelegateTransaction',
    'pendingDRepRegistrationTransaction',
    'pendingDRepRetireTransaction',
    'pendingVotingTransaction',
  ],
};

const persistedPendingtransactionReducer = persistReducer(
  transactionPersistConfig,
  transactionSlice.reducer
);
export const selectPendingTransactions = (state: RootState): ITransaction =>
  state.pendingTransactions;
export const {
  setPendingTransactions,
  resetDelegatePendingTransaction,
  resetMintpendingTransaction,
  resetDRepRegistrationPendingTransaction,
  resetDRepRetirePendingTransaction,
  resetVotingPendingTransaction,
} = transactionSlice.actions;
export default persistedPendingtransactionReducer;
