import React from 'react';

export function Check(props: React.SVGAttributes<{}>) {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.5 1.75C6.45 1.75 1.5 6.7 1.5 12.75C1.5 18.8 6.45 23.75 12.5 23.75C18.55 23.75 23.5 18.8 23.5 12.75C23.5 6.7 18.55 1.75 12.5 1.75ZM12.5 21.55C7.649 21.55 3.7 17.601 3.7 12.75C3.7 7.899 7.649 3.95 12.5 3.95C17.351 3.95 21.3 7.899 21.3 12.75C21.3 17.601 17.351 21.55 12.5 21.55ZM18.3272 8.67176C17.8981 8.23959 17.1991 8.23792 16.7684 8.66857L10.3 15.137L8.22725 13.0723C7.7983 12.645 7.10437 12.6456 6.67625 13.0738C6.24754 13.5025 6.24754 14.1975 6.67625 14.6263L9.52218 17.4722C9.95176 17.9018 10.6482 17.9018 11.0778 17.4722L18.3249 10.2251C18.7534 9.79657 18.7542 9.10178 18.3272 8.67176Z"
        fill="currentColor"
      />
    </svg>
  );
}
