import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { EpochParams } from '@src/models/types';
import type { RootState } from '@src/store/store';

export interface ConfigState {
  protocolParams: EpochParams | null;
  fee: (number | bigint)[] | bigint[];
}

const initialState: ConfigState = {
  protocolParams: null,
  fee: [],
};

export const configSlice = createSlice({
  name: 'configState',
  initialState,
  reducers: {
    setProtocolParams: (state, action: PayloadAction<EpochParams>) => {
      state.protocolParams = action.payload;
    },
    setFee: (state, action: PayloadAction<(number | bigint)[] | bigint[]>) => {
      state.fee = action.payload;
    },
  },
});

export const { setProtocolParams, setFee } = configSlice.actions;
export const selectProtocolParams = (state: RootState): EpochParams | null =>
  state.config.protocolParams;
export const selectFee = (
  state: RootState
): (number | bigint)[] | bigint[] | null => state.config.fee;

export default configSlice.reducer;
