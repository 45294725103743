import { Tooltip } from 'react-tooltip';
import cn from 'classnames';

export interface CustomizeToolTipProps {
  title?: string;
  description?: string;
  className?: string;
  id: string;
}
export default function CustomizeToolTip({
  title,
  description,
  className,
  id,
}: CustomizeToolTipProps) {
  return (
    <Tooltip
      place="top"
      opacity={100}
      className={cn(
        'label12 flex max-w-[320px] flex-col !gap-1 !rounded-lg !bg-white !p-3 shadow-l-Shadow-4 dark:!bg-dark-neutral-400 dark:shadow-Tooltip-shadow',
        className
      )}
      id={id}
    >
      {title && (
        <p className="font-semibold text-gray-900 dark:text-white">{title}</p>
      )}
      {description && (
        <div
          dangerouslySetInnerHTML={{
            __html: description,
          }}
          className="label12 text-gray-600 dark:text-white"
        />
      )}
    </Tooltip>
  );
}
