import cn from 'classnames';

type BadgeVariant = 'solid' | 'light' | 'outline';
export type BadgeColor = 'success' | 'error' | 'information' | 'warning';

interface BadgeProps {
  variant?: BadgeVariant;
  color?: BadgeColor;
  label: string;
  className?: string;
}

const VARIANT_COLOR_CLASSES: Record<
  BadgeVariant,
  Record<BadgeColor, string>
> = {
  solid: {
    success: 'bg-semantic-success-600 text-white',
    error: 'bg-semantic-error-600 text-white',
    information: 'bg-semantic-information-600 text-white',
    warning: 'bg-semantic-warning-600 text-white',
  },
  light: {
    success: 'bg-semantic-success-100 text-semantic-success-600',
    error: 'bg-semantic-error-100 text-semantic-error-600',
    information: 'bg-semantic-information-100 text-semantic-information-600',
    warning: 'bg-semantic-warning-100 text-semantic-warning-600',
  },
  outline: {
    success: 'border border-semantic-success-600 text-semantic-success-600',
    error: 'border border-semantic-error-600 text-semantic-error-600',
    information:
      'border border-semantic-information-600 text-semantic-information-600',
    warning: 'border border-semantic-warning-600 text-semantic-warning-600',
  },
};

function Badge({
  variant = 'solid',
  color = 'success',
  label,
  className,
}: BadgeProps) {
  const variantClass = VARIANT_COLOR_CLASSES[variant][color];

  return (
    <div
      className={cn(
        'label12 flex h-[22px] items-center rounded-md px-[6px] font-medium',
        variantClass,
        className
      )}
    >
      {label}
    </div>
  );
}

export default Badge;
