import Image from 'next/image';
import Spinner from '@src/assets/images/spinner.gif';

export default function TransactionInProgressBadge() {
  return (
    <div className="flex h-9 items-center gap-1 rounded-xl bg-semantic-warning-600 px-3">
      <Image
        src={Spinner}
        alt="spinner"
        height={14}
        width={14}
        className="object-cover"
      />
      <p className="body14 font-semibold text-white">Transaction in progress</p>
    </div>
  );
}
