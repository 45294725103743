import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import environments from '@src/configs/environments';
import type { IGovernanceAction } from '@src/models/types';

export const GOVERNANCE_ACTION_PATH = 'governanceAction';
const GOVERNANCE_ACTION_TAG = 'governanceActionTag';
export const governanceActionApi = createApi({
  reducerPath: GOVERNANCE_ACTION_PATH,
  tagTypes: [GOVERNANCE_ACTION_TAG],
  refetchOnReconnect: true,
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 0,
  baseQuery: fetchBaseQuery({
    baseUrl: environments.BASE_API_URL,
    credentials: 'include',
    prepareHeaders: (headers) => {
      headers.append('Content-Type', 'application/json');
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getProposalById: builder.query<IGovernanceAction, string>({
      query: (id) => ({
        url: `/proposal/${id}?vote_count=true`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetProposalByIdQuery, useLazyGetProposalByIdQuery } =
  governanceActionApi;
