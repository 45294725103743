import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import environments from '@src/configs/environments';
import type {
  ConfigResponse,
  ICommitteGovStateResponse,
} from '@src/store/config/types';
import type { EpochParams } from '@src/models/types';

export const configApi = createApi({
  refetchOnReconnect: true,
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 0,
  baseQuery: fetchBaseQuery({
    baseUrl: environments.BASE_API_URL,
    credentials: 'include',
    prepareHeaders: (headers) => {
      headers.append('Content-Type', 'application/json');
      return headers;
    },
  }),
  endpoints: (builder) => ({
    config: builder.query<ConfigResponse, void>({
      query: () => ({
        url: `/config`,
        method: 'GET',
      }),
    }),
    protocolParams: builder.query<EpochParams, number>({
      query: (epochNumber) => ({
        url: `/blockchain/protocol-params`,
        params: {
          epoch_no: epochNumber,
        },
        method: 'GET',
      }),
    }),
    committeGovState: builder.query<ICommitteGovStateResponse, void>({
      query: () => ({
        url: `/blockchain/gov-state/committee`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useConfigQuery,
  useLazyProtocolParamsQuery,
  useCommitteGovStateQuery,
} = configApi;
